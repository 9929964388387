import React from 'react';
import Drawer from '../../../../components/UI/Drawer/Drawer';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup'
import PartTypeEnum from '../../../../constants/enums/PartTypeEnum';
import FormGroup from '../../../../components/UI/Forms/FormGroup';
import { FormEuro } from '../../../../components/UI/Forms/FormEuro';
import { FormSelect } from '../../../../components/UI/Forms/FormSelect';
import { FormInput } from '../../../../components/UI/Forms/FormInput';
import CancelButton from '../../../../components/UI/Buttons/CancelButton';
import FormikResetButton from '../../../../components/UI/Buttons/FormikResetButton';
import FormikSaveButton from '../../../../components/UI/Buttons/Save/FormikSaveButton';
import partsService from '../../../../services/parts/partsService';
import { toast } from 'react-toastify';
import { FormPercentage } from '../../../../components/UI/Forms/FormPercentage';
import RealTimePartPriceCalculator from '../../components/RealTimePartPriceCalculator';

const validationSchema = Yup.object().shape({
  price: Yup.number().min(0).nullable().notRequired(),
  cost: Yup.number().min(0).nullable().notRequired(),
  note: Yup.string().max(800).nullable().notRequired(),
  drawing_note: Yup.string().max(200).nullable().notRequired(),
  margin: Yup.number().min(0).max(100).nullable(),
  company_id: Yup.number().when('part_type', {
    is: PartTypeEnum.values.EXCLUSIVE,
    then: Yup.number().nullable().required(),
    otherwise: Yup.number().nullable().notRequired(3),
  }),
  part_category_id: Yup.number().nullable().notRequired()
})


export const PartDetailsDrawer = ({ part, isOpen, setIsOpen, onUpdate, categories = [] }) => {

  const { t } = useTranslation()
  const initialValues = {
    price: part?.price || 0,
    cost: part?.cost || 0,
    note: part?.note ?? "",
    margin: part?.margin ? part?.margin * 100 : "",
    drawing_note: part?.drawing_note ?? "",
    company_id: part?.company?.id ?? null,
    part_category_id: part?.part_category?.id ?? ""
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => partsService.update(part.uuid, values)
    .then(res => onUpdate(res))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('edit')}>
      <FormikProvider value={formik}>
        <Form>
          <div className='row'>
            <div className='col col-md-6'>
              <FormGroup name='cost' label={t('parts.cost')}>
                <FormEuro name='cost'/>
              </FormGroup>
            </div>
            <div className='col col-md-6'>
              <FormGroup name='price' label={t('parts.price')}>
                <FormEuro name='price'/>
              </FormGroup>
            </div>
            <RealTimePartPriceCalculator
              cost={formik.values.cost}
              price={formik.values.price}
              margin={formik.values.margin}
              part_category_id={formik.values.part_category_id}
              categories={categories}
            />
            <div className='col col-md-6'>
              <FormGroup name='margin' label={t('parts.margin')}>
                <FormPercentage name='margin' max={99.9}/>
              </FormGroup>
            </div>
            <div className='col col-md-6'>
              <FormGroup name="part_category_id" required={false} label={t('part_categories.sing')}>
                <FormSelect
                  name="part_category_id"
                  placeholder={t('part_categories.sing')}
                  options={categories}
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup name="note" required={false} label={t('note')}>
            <FormInput rows={10} as='textarea' name="note"/>
          </FormGroup>
          <FormGroup name="drawing_note" required={false} label={t('parts.drawing_note')}>
            <FormInput rows={5} as='textarea' name="drawing_note"/>
          </FormGroup>
          <div className="d-flex align-items-center justify-content-between">
            <CancelButton onClick={() => setIsOpen(false)}/>
            <div className="d-flex gap-1">
              <FormikResetButton/>
              <FormikSaveButton/>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Drawer>
  )

}
