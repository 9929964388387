import { workActionTypes } from './workActionTypes';

export const workReducer = (state, action) => {

  const { type, payload } = action


  switch (type) {
    case workActionTypes.INIT : {
      return { ...state, work: payload }
    }
    case workActionTypes.INIT_MEDIA : {
      return { ...state, media: payload }
    }
    case workActionTypes.INIT_COLOR_PARTS : {
      return { ...state, color_parts: payload }
    }
    case workActionTypes.UPDATE_PRODUCTION_STATES : {
      return { ...state, production_states: payload }
    }
    case workActionTypes.INIT_HISTORY: {
      return { ...state, history: payload }
    }
    case workActionTypes.UPDATE_COMMERCIAL_STATES : {
      return { ...state, commercial_states: payload }
    }
    case workActionTypes.STORE_MEDIA: {
      return { ...state, media: [...state.media, payload] }
    }
    case workActionTypes.DELETE_MEDIA: {
      return { ...state, media: state.media.filter(m => m.id !== payload) }
    }
    case workActionTypes.DELETE_ALL_VAULT_MEDIA: {
      return { ...state, media: state.media.filter(m => m.collection !== 'vault') }
    }
    case workActionTypes.UPDATE_COLOR_CONFIGS: {
      return {
        ...state,
        media: state.media.filter(m => m.collection !== 'vault'), // Cancello le tavole generali
        work: {
          ...state.work,
          sub_works: state.work.sub_works.map(
            sw => sw.color_configs.length > 0 ? sw : { ...sw, media: [], must_be_modified: true } // Cancello le tavole delle aree senza color_configs
          ),
          color_configs: payload
        }
      }
    }
    case workActionTypes.UPDATE_SUBWORK_COLOR_CONFIGS: {
      const { sub_work_uuid, color_configs } = payload

      return {
        ...state,
        media: state.media.filter(m => m.collection !== 'vault'), // Cancello le tavole generali
        work: {
          ...state.work,
          sub_works: state.work.sub_works.map(
            sw => sw.uuid === sub_work_uuid ? { ...sw, color_configs: color_configs, media: [], must_be_modified: true } : sw // Cancello le tavole della singola area
          )
        }
      }
    }
    case workActionTypes.TOGGLE_SUBWORK: {
      const { sub_work_uuid, response } = payload

      return {
        ...state,
        media: state.media.filter(m => m.collection !== 'vault'), // Cancello le tavole generali
        work: {
          ...state.work,
          sub_works: state.work.sub_works.map(sw => sw.uuid === sub_work_uuid
            ? {
              ...sw,
              is_enabled: response.is_enabled,
              must_be_modified: response.must_be_modified,
              items: [], // Cancello la distinta base
              media: [] // Cancello le tavole della singola area
            } : sw
          )
        }
      }
    }
    case workActionTypes.UPDATE_SUBWORK_ITEM_PRICE_OR_COST: {
      return ({
          ...state,
          work: {
            ...state.work,
            sub_works: state.work.sub_works.map(sw => ({
                ...sw,
                items: sw.items.map(i => i.uuid === payload.uuid ? {
                  ...i,
                  part_price: payload.price,
                  part_cost: payload.cost
                } : i)
              })
            )
          }
        }
      )
    }
    case workActionTypes.UPDATE_DETAILS: {
      return {
        ...state, work: {
          ...state.work,
          description: payload.description,
          note: payload.note,
          delivery_date: payload.delivery_date,
          forecast_date: payload.forecast_date,
          delivery_status: payload.delivery_status,
          has_new_guide: payload.has_new_guide,
          tags: payload.tags
        }
      }
    }
    case workActionTypes.UPDATE_PARTNERS: {
      return { ...state, work: { ...state.work, companies: payload } }
    }
    case workActionTypes.UPDATE_USERS: {
      return { ...state, work: { ...state.work, users: payload } }
    }
    case workActionTypes.UPDATE_SAP_ORDERS: {
      return { ...state, work: { ...state.work, sap_orders: payload.sap_orders } }
    }
    case workActionTypes.UPDATE_CUSTOMER: {
      return {
        ...state, work: {
          ...state.work,
          bill_to: payload.bill_to,
          company: payload.company,
          company_location: payload.company_location,
          company_person: payload.company_person,
          work_location: payload.work_location,
        }
      }
    }
    case workActionTypes.CREATE_OFFER: {
      return {
        ...state, work: {
          ...state.work,
          offer: payload
        }
      }
    }
    case workActionTypes.TRIANGULATION_CHANGED: {

      return {
        ...state, work: {
          ...state.work,
          has_triangulation: payload !== null,
          company_location: payload === null ? state.work.company_location : null,
          work_location: payload,
        }
      }
    }
    default:
      console.log('ACTION')
  }

}

export default workReducer
