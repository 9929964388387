import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../components/UI/Buttons/TableLink';
import CodeTypeEnumDisplay from '../../../components/UI/Displays/CodeTypeEnumDisplay';
import PartTypeEnumDisplay from '../../../components/UI/Displays/PartTypeEnumDisplay';
import TranslatableDisplay from '../../../components/UI/Displays/TranslatableDisplay';

const codeTableConfig = [{
  Header: <Trans i18nKey="codes.code"/>,
  accessor: 'code',
  Cell: ({ row, value }) => <TableLink to={`/codes/${row.original.uuid}`} value={value}/>,
}, {
  Header: <Trans i18nKey="description"/>,
  accessor: 'description',
  Cell: ({ row, value }) => (
    <TranslatableDisplay
      translatableArray={row.original.descriptions}
      currentTranslation={value}
    />
  ),
  disableSortBy: true,
}, {
  Header: <Trans i18nKey="code_type"/>,
  accessor: 'code_type',
  Cell: ({ value }) => <CodeTypeEnumDisplay type={value}/>,
}, {
  Header: <Trans i18nKey="part_type"/>,
  accessor: 'part_type',
  Cell: ({ value }) => <PartTypeEnumDisplay type={value}/>,
}];

export default codeTableConfig;
