import React, { useEffect, useState } from 'react';
import { FormDisplay } from '../../../components/UI/Displays/FormDisplay';
import EuroDisplay from '../../../components/UI/Displays/EuroDisplay';
import PercentageDisplay from '../../../components/UI/Displays/PercentageDisplay';
import { useTranslation } from 'react-i18next';
import DecimalDisplay from '../../../components/UI/Displays/DecimalDisplay';

const RealTimePartPriceCalculator = ({
                                       price = 0,
                                       cost = 0,
                                       margin,
                                       part_category_id,
                                       categories,
                                     }) => {

  const { t } = useTranslation()
  const [realTimeCalculatedPriceByMargin, setRealTimeCalculatedPriceByMargin] = useState(0)
  const [realTimeCalculatedPriceByPartCategory, setRealTimeCalculatedPriceByPartCategory] = useState(0)
  const [realTimeCalculatedCoeff, setRealTimeCalculatedCoeff] = useState(0)

  const getPartCategoryMargin = id => id ? categories.find(c => c.value === id)?.margin : 0
  const getPartMargin = () => margin ? margin / 100 : 0;
  const getCalculatedPrice = margin => cost ? (cost / (1 - margin)) / 0.425 : 0;
  const getPartCoeff = () => cost ? price / cost : 0;
  const formatNumber = num => num === Infinity || num < 0 ? 0 : Math.round((num + Number.EPSILON) * 100) / 100;

  useEffect(() => {
    setRealTimeCalculatedPriceByMargin(formatNumber(getCalculatedPrice(getPartMargin())))
    setRealTimeCalculatedPriceByPartCategory(formatNumber(getCalculatedPrice(getPartCategoryMargin(part_category_id))))
    setRealTimeCalculatedCoeff(formatNumber(getPartCoeff()))
  }, [price, cost, margin, part_category_id]);

  return (
    <>
      <div className='col col-md-6'>
        <FormDisplay label={t('parts.calculated_price_by_margin')}>
          <EuroDisplay value={realTimeCalculatedPriceByMargin}/>
        </FormDisplay>
        <FormDisplay label={t('parts.calculated_price_by_part_category')}>
          <EuroDisplay value={realTimeCalculatedPriceByPartCategory}/>
        </FormDisplay>
      </div>
      <div className='col col-md-6'>
        <FormDisplay label={t('parts.calculated_coeff')}>
          <DecimalDisplay value={realTimeCalculatedCoeff}/>
        </FormDisplay>
      </div>
    </>
  )

}

export default RealTimePartPriceCalculator
