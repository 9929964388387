import React from 'react';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import partTypeEnum from '../../../constants/enums/PartTypeEnum';

function PartTypeEnumDisplay({ type }) {
  const partType = partTypeEnum.collection.find((pt) => pt.value === type);
  const { t } = useTranslation();

  if (partType) {
    return <Badge className="p-1 font-size-11" color={partType.color}>{t(partType.label)}</Badge>;
  }

  return <></>;
}

export default PartTypeEnumDisplay;
