import React from 'react';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function PartStateEnumDisplay({ state }) {
  const { t } = useTranslation();

  return (
    <Badge
      className="p-1 font-size-11"
      color={state?.color}
    >
      {t(state?.label)}
    </Badge>
  );


}

export default PartStateEnumDisplay;
