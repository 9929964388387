import api from '../../configs/api.config';
import FileDownload from 'js-file-download';

const latest = offerUuid => api.get(`/offers/${offerUuid}/offer-revisions`).then(res => res.data);

const fetch = (
  offerUuid,
  offerRevisionUuid
) => api.get(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}`).then(res => res.data.data);

const store = (
  offerUuid,
  params
) => api.post(`/offers/${offerUuid}/offer-revisions`, params).then(res => res.data.data);

const massivePriceUpdate = (
  offerUuid,
  offerRevisionUuid,
  params
) => api.post(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/massive-price-update`, params).then(res => res.data.data);

const massivePriceReset = (
  offerUuid,
  offerRevisionUuid,
  params
) => api.post(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/massive-price-reset`, params).then(res => res.data.data);

const update = (
  offerUuid,
  offerRevisionUuid,
  params
) => api.patch(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}`, params).then(res => res.data.data);

const storeWork = (
  offerUuid,
  offerRevisionUuid
) => api.post(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/works`).then(res => res.data.data);

const getMedia = (
  offerUuid,
  offerRevisionUuid
) => api.post(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/media`).then(res => res.data.data);

const print = (
  offerUuid,
  offerRevisionUuid,
  filename,
  params,
) => api.post(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/print`, params, { responseType: 'blob' })
.then(res => FileDownload(res.data, filename));

const updateColorConfigs = (
  { offerUuid, offerRevisionUuid },
  params
) => api.post(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/color-configs`, params).then(res => res.data.data);

const changeState = (
  offerUuid,
  offerRevisionUuid,
  params
) => api.post(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/change-state`, params).then(res => res.data.data);

const availableStates = (
  offerUuid,
  offerRevisionUuid,
  params
) => api.get(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/available-states`, params).then(res => res.data.data);

const offerRevisionsService = {
  latest,
  fetch,
  store,
  update,
  getMedia,
  print,
  massivePriceUpdate,
  massivePriceReset,
  updateColorConfigs,
  changeState,
  availableStates,
  storeWork
};

export default offerRevisionsService;
