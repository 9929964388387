import React from 'react';
import EuroDisplay from '../../../../components/UI/Displays/EuroDisplay';
import DecimalDisplay from '../../../../components/UI/Displays/DecimalDisplay';
import PartTypeEnum from '../../../../constants/enums/PartTypeEnum';
import { useTranslation } from 'react-i18next';

export const PartCostsDetails = ({ part }) => {

  const { t } = useTranslation()

  return (
    <div className='card w-100'>
      <div className='card-body'>
        <div className='d-flex justify-content-between align-items-center gap-4'>
          <span className='text-black-50'>{t('parts.cost')} - {t('parts.price')}</span>
        </div>
        <ul className='list-group list-group-flush'>
          <li className="list-group-item px-1">
            <div className='fw-bold'>{t('parts.cost')}</div>
            <EuroDisplay value={part.cost}/>
          </li>
          <li className="list-group-item px-1">
            <div className='fw-bold'>{t('parts.price')}</div>
            <EuroDisplay value={part.price}/>
          </li>
          <li className="list-group-item px-1">
            <div className='fw-bold'>{t('parts.calculated_price_by_margin')}</div>
            <EuroDisplay value={part.calculated_price_by_margin}/>
          </li>
          <li className="list-group-item px-1">
            <div className='fw-bold'>{t('parts.calculated_price_by_part_category')}</div>
            <EuroDisplay value={part.calculated_price_by_part_category}/>
          </li>
          <li className="list-group-item px-1">
            <div className='fw-bold'>{t('parts.hypothetical_cost')}</div>
            <EuroDisplay value={part.hypothetical_cost}/>
          </li>
          <li className="list-group-item px-1">
            <div className='fw-bold'>{t('parts.calculated_coeff')}</div>
            <DecimalDisplay value={part.calculated_coeff}/>
          </li>
          {part.part_type === PartTypeEnum.values.EXCLUSIVE ?
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('companies.sing')}</div>
              {part.company?.business_name}
            </li>
            : <></>}
        </ul>
      </div>
    </div>
  )

}
